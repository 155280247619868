import './assets/css/app.min.css';
import { useState, useEffect } from 'react';
import { LoadTitlePage } from './Components/Public/Functions/LoadTitlePage.js';
import { Router } from './Routes/Router.js';
import { HostBackend } from './Routes/HostBackend.js';
import { FilterParkAndUnivers } from './Components/Public/Filters/FilterParkAndUnivers.js';

function App() {
    LoadTitlePage();
    const uri = window.location.href;
    const main = uri.includes('admin') ? 'admin' : 'public';
    const hosting = HostBackend();
    const [parks, setParks] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasParks = await fetch(`${hosting}/nav/parks`);
            const parks = await datasParks.json();
            setParks(parks);
        };
        fetchData();
    }, [hosting]);
    const navParks = FilterParkAndUnivers(parks);
    return (
        <div id="main" className={`main ${main}`}>
            <Router navParks={navParks} />
        </div>
    );
}
export default App;
