import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { GetStringTotal } from '../../Components/Public/Functions/GetStringTotal.js';
import { GetUpperCase } from '../../Components/Public/Functions/GetUpperCase.js';
/* document */
import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Header } from '../../Components/Public/Document/Header.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { BackTop} from '../../Components/Public/Document/BackTop.js';
import { ScrollTop } from '../../Components/Public/Functions/ScrollTop.js';

export const Characters = (props) => {
    const navParks = props.navParks;
    const hosting = HostBackend();
    const [characters, setCharacters] = useState([]);
    const [body, setBody] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const charactersDatas = await fetch(`${hosting}/characters`);
            const characters = await charactersDatas.json();
            setCharacters(characters);
            const bodyDatas = await fetch(`${hosting}/body/characters`);
            const body = await bodyDatas.json();
            setBody(body);
        };
        fetchData();
        ScrollTop();
    }, [hosting]);
    let items = {
        created: '1992-04-12',
        author: 'Walt Disney Imagineering',
        name: 'Personnages des\nParcs Disneyland',
        link_name: 'Wikipedia',
        link_href: 'https://wikipedia.com'
    };
    const total = characters.length;
    const description = body[0] ? body[0].description : '';
    const title_list = `${GetUpperCase(GetStringTotal('personnage',total))} (${total})`;
    return (
        <div className="origins">
            <Navigation navParks={navParks} />
            <Header />
            <BreadCrumb />
            <section className="infos">
                <div className="list">
                    <div className="title-list">{title_list}</div>
                    {characters.map((item, i) => {
                        return (
                            <BlockLogo label="characters" item={item} key={`i-${i}`} />
                        )
                    })}
                </div>
                <div className="description">
                    <BlockTitle label="characters" item={items} total={total} />
                    <p>{description}</p>
                    <BlockOutlink item={items} />
                </div>
            </section>
            <BackTop />
            <Footer />
        </div>
    )
}
