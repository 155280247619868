import { useEffect } from 'react';
/* datas */
import { FilterCategory } from '../../Components/Public/Filters/FilterCategory.js';
import { CountDatas } from '../../Components/Public/Functions/CountDatas.js';
import { GetStringTotal } from '../../Components/Public/Functions/GetStringTotal.js';
import { GetUpperCase } from '../../Components/Public/Functions/GetUpperCase.js';
/* document */
import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Header } from '../../Components/Public/Document/Header.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { Jumbotron } from '../../Components/Public/Document/Jumbotron.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BackTop} from '../../Components/Public/Document/BackTop.js';
import { ScrollTop } from '../../Components/Public/Functions/ScrollTop.js';

export const Categories = (props) => {
    const parks = props.navParks;
    const attractions = props.att;
    const characters = props.car;
    const origins = props.ori;
    const experiments = props.exp;
    const ttunivers = parks[0] ? parks[0].univers.length + parks[1].univers.length : 0;
    const categories = FilterCategory('home', props.att);
    const count_category = props.att ? CountDatas('category', attractions) : [];
    const title_list = `${GetUpperCase(GetStringTotal('catégorie',categories.length))} (${categories.length})`;
    const datas = [
        { label: `${parks.length} parcs` },
        { label: `${ttunivers} univers` },
        { label: `${attractions.length} attractions` },
        { label: `${origins.length} films & séries` },
        { label: `${characters.length} personnages` },
        { label: `${experiments.length} expériences` },
    ]
    useEffect(() => {
        ScrollTop();
    });
    return (
        <div className="categories">
            <Navigation navParks={parks} />
            <Header />
            <Jumbotron datas={datas} />
            <BreadCrumb />
            <section className="infos">
                <div className="list">
                    <div className="title-list">{title_list}</div>
                    {categories.map((item, i) => {
                        let tt_category = count_category[item.cslug];
                        let legend = `${tt_category} ${GetStringTotal('attraction', tt_category)}`;
                        return (
                            <BlockLogo label="categories" item={item} legend={legend} key={`i-${i}`} />
                        )
                    })}
                </div>
            </section>
            <BackTop />
            <Footer />
        </div>
    )
}
