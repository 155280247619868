import { BlockLink } from './BlockLink.js'
import { LogoParks } from '../../../Files/Logos/LogoParks.js';
import { LogoUnivers } from '../../../Files/Logos/LogoUnivers.js';
import { LogoCategories } from '../../../Files/Logos/LogoCategories.js';
import { LogoCharacter } from '../../../Files/Logos/LogoCharacter.js';
import { LogoImgAttraction } from '../../../Files/Logos/LogoImgAttraction.js';
import { LogoExperiment } from '../../../Files/Logos/LogoExperiment.js';
import { LogoImgOrigin } from '../../../Files/Logos/LogoImgOrigin.js';
import { GetBkgLogo } from '../Functions/GetBkgLogo.js';

export const BlockLogo = (props) => {
    const label = props.label;
    const item = props.item;
    let logo, bkgLogo, legend;
    switch (label) {
        case 'park':
        case 'home':
            default:
                logo = LogoParks(item.pslug);
                return (
                <div className="logo">
                    <a className="icon" href={item.proute} alt={item.pname} title={item.pname}>
                        <img className="img" src={logo} title={item.pname} alt={item.pname} loading="lazy" />
                    </a>
                    <div className="legend">
                        <div className="title">{item.pname}</div>
                    </div>
                </div>
            )
        case 'categories':
            logo = LogoCategories(item.cslug);
            legend = props.legend;
            return (
            <div className="logo">
                <a className="icon" href={item.croute} alt={item.cname} title={item.cname}>
                    <img className="img" src={logo} title={item.cname} alt={item.cname} loading="lazy" />
                </a>
                <div className="legend">
                    <div className="title">{item.cname}</div>
                    <div>{legend}</div>
                </div>
            </div>
        )
    case 'univers':
            logo = LogoUnivers(item.uslug);
            return (
                <div className="logo">
                    <div className="icon">
                        <img className="img" src={logo} title={item.uname} alt={item.uname} loading="lazy" />
                    </div>
                    <div className="legend">
                        <div className="title">{item.uname}</div>
                    </div>
                </div>
            )
        case 'characters':
            logo = LogoCharacter(item.oslug, item.aslug);
            return (
                <div className="logo">
                    <a className="icon" href={item.aroute} alt={item.aname} title={item.aname}>
                        <img className="img" src={logo} title={item.aname} alt={item.aname} loading="lazy" />
                    </a>
                    <div className="legend">
                        <div className="title">{item.aname}</div>
                        <div className="created">{item.created.split('-')[0]}</div>
                        <BlockLink label="link-true" href={item.proute} name={item.pname} />
                        <BlockLink label="link-date" href={item.oroute} name={item.oname} />
                    </div>
                </div>
            )
        case 'character':
            logo = LogoCharacter(item.oslug, item.slug);
            return (
                <div className="logo">
                    <div className="icon">
                        <img className="img" src={logo} title={item.aname} alt={item.aname} loading="lazy" />
                    </div>
                    <div className="legend">
                        <div className="title">{item.name}</div>
                        <BlockLink label="link-true" href={'/personnages'} name={'Personnages'} />
                        <BlockLink label="link-true" href={item.proute} name={item.pname} />
                        <BlockLink label="link-true" href={item.oroute} name={item.oname} />
                    </div>
                </div>
            )
        case 'experiments':
            logo = LogoExperiment(item.path);
            return (
                <div className="logo">
                    <a className="icon" href={item.route} alt={item.name}>
                        <img className="img" src={logo} title={item.name} alt={item.name} loading="lazy" />
                    </a>
                    <div className="legend">
                        <div className="number">{item.number}</div>
                        <div className="title">{item.name}</div>
                    </div>
                </div>
            )
        case 'experiment':
            logo = LogoExperiment(item.path);
            return (
                <div className="logo">
                    <div className="icon">
                        <img className="img" src={logo} title={item.name} alt={item.name} loading="lazy" />
                    </div>
                    <div className="legend">
                        <div className="number">{item.number}</div>
                        <div className="title">{item.name}</div>
                        <div className="serie">{item.sname}</div>
                    </div>
                </div>
            )
        case 'origins':
            logo = LogoImgOrigin('icon', item.oslug);
            return (
                <div className="logo">
                    <a className="icon" href={item.oroute} alt={item.oname} title={item.oname}>
                        <img className="img" src={logo} title={item.oname} alt={item.oname} loading="lazy" />
                    </a>
                    <div className="legend">
                        <div className="title">{item.oname}</div>
                        <BlockLink label="link-total" name="attraction" total={props.tt_ori} />
                        <BlockLink label="link-total" name="personnage" total={props.tt_car} />
                        <div className="origintype">
                            <div className="number">{item.onumber}<sup>e</sup></div>
                            <div className="name">{item.otname}</div>
                            <div className="date">{item.ocreated.split('-')[0]}</div>
                        </div>
                    </div>
                </div>
            )
        case 'origin':
            logo = (item.cname) ? LogoImgAttraction('icon', item.aslug) : LogoCharacter(item.oslug, item.aslug);
            bkgLogo = (item.cname) ? GetBkgLogo(item.aroute) : '';
            const cname = (item.cname) ? item.cname : 'Personnages';
            const croute = (item.cname) ? `/categorie/${item.cslug}` : '/personnages';
            return (
                <div className={`logo ${bkgLogo}`}>
                    <a className="icon" href={item.aroute} alt={item.aname} title={item.aname}>
                        <img className="img" src={logo} title={item.aname} alt={item.aname} loading="lazy" />
                    </a>
                    <div className="legend">
                        <div className="title">{item.aname}</div>
                        <BlockLink label="link-true" href={item.proute} name={item.pname} />
                        <BlockLink label="link-true" href={croute} name={cname} />
                    </div>
                </div>
            )
        case 'attraction':
            logo = LogoImgAttraction('icon', item.aslug);
            bkgLogo = GetBkgLogo(item.aroute);
            return (
                <div className={`logo ${bkgLogo}`}>
                    <div className="icon">
                        <img className="img" src={logo} title={item.aname} alt={item.aname} loading="lazy" />
                    </div>
                    <div className="legend">
                        <div className="title">{item.aname}</div>
                        <BlockLink label="link-true" href={`/categorie/${item.cslug}`} name={item.cname} />
                        <BlockLink label="link-true" href={`/origine/${item.oslug}`} name={item.oname} />
                    </div>
                </div>
            )
        case 'category':
            logo = LogoImgAttraction('icon', item.aslug);
            bkgLogo = GetBkgLogo(item.aroute);
            return (
                <div className={`logo ${bkgLogo}`}>
                    <a className="icon" href={item.aroute} alt={item.aname}>
                        <img className="img" src={logo} title={item.aname} alt={item.aname} loading="lazy" />
                    </a>
                    <div className="legend">
                        <div className="title">{item.aname}</div>
                        <div className="created">{item.created.split('-')[0]}</div>
                        <BlockLink label="link-true" href={item.uroute} name={item.uname} />
                        <BlockLink label="link-true" href={item.oroute} name={item.oname} />
                    </div>
                </div>
            )
    }
}