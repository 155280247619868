import { LogoDefault } from './LogoDefault.js';

export const LogoCategories = (slug) => {
    let src;
    try {
        src = require(`../../assets/images/categories/${slug}.webp`);
    } catch (e) {
        if (e.code !== 'MODULE_NOT_FOUND') {
            throw e;
        }
        src = LogoDefault();
    }
    return src;
}